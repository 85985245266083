export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Galerie</h2>
          <p>
          Prohlédněte si naši prodejnu na adrese: Kinského 3384, Česká Lípa a zde prodávaný sortiment: 
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            <div className='col-sm-6 col-md-4 col-lg-6'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
              
                    <img
                      src='img/portfolio/h1.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                 
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-6'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                
                    <img
                      src='img/portfolio/h3.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
              
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-6'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                
                    <img
                      src='img/portfolio/h4.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                 
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-6'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
               
                    <img
                      src='img/portfolio/h5.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
             
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-12'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '} 
                
                    <img
                      src='img/portfolio/h2.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                
                </div>
              </div>
            </div>
          </div>
        </div>
        

      </div>
    </div>


  )
}
