export const Contact = (props) => {
 


  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Kontakt</h2>
              </div>
            </div>
          </div>
          <div className='col-md-12 contact-info'  >
            <div className='contact-item'>
              <h3>Otevírací doba: Pondělí až Pátek: po telefonické dohodě</h3>
              
              <h3>
                <span>
                  <i className='fa fa-map-marker'></i> Adresa
                </span>
                {props.data ? props.data.address : 'loading'}
              </h3>
            </div>
            <div className='contact-item'>
              <h3>
                <span>
                  <i className='fa fa-phone'></i> Telefon
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </h3>
            </div>
            <div className='contact-item'>
              <h3>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
                </h3>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            
           Vytvořil Ing. Jiří Bayer ml. s použitím šablony &copy; 2020 Issaaf Kattan React Land Page Template. Design by{' '}
            <a href='http://www.templatewire.com' rel='nofollow'>
              TemplateWire
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
