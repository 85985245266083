export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Co nabízíme</h2>
          <p>
          Elektrické ohradníky jsou zařízení, která vytvářejí hrazení nikoli fyzickou zábranou, ale pomocí elektrických impulzů. Ohradník udrží zvířata ve vymezeném prostoru pomocí citelného elektrického výboje, který však nemá takovou sílu, aby zvířeti nebo náhodnému kolemjdoucímu ublížil. Máme širokou nabídku elektrických ohradníků pro různá zvířata. Elektrické ohradníky dělíme podle druhu zvířat: pro koně, skot, ovce, kozy, lesní a divokou zvěř.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
